/* ############################# Common classes ############################# */

.coca-d-none {
  display: none !important;
}

.coca-cowi img {
  width: 100% !important;
}
.coca-cowi .coca-d-flex {
  display: flex !important;
}
.coca-cowi .coca-d-col {
  flex: 1 !important;
}
.coca-cowi .coca-flex-grow-0 {
  flex-grow: 0 !important;
}
.coca-cowi .coca-flex-grow-1 {
  flex-grow: 1 !important;
}
.coca-cowi .coca-flex-shrink-0 {
  flex-shrink: 0 !important;
}
.coca-cowi .coca-flex-shrink-1 {
  flex-shrink: 1 !important;
}
.coca-cowi .coca-d-block {
  display: block !important;
}
.coca-cowi .coca-align-items-center {
  align-items: center !important;
}
.coca-cowi .coca-justify-content-center {
  justify-content: center !important;
}
.coca-cowi .coca-justify-content-between {
  justify-content: space-between !important;
}
.coca-cowi .coca-justify-content-end {
  justify-content: flex-end !important;
}
.coca-cowi .coca-text-center {
  text-align: center !important;
}
.coca-cowi .coca-p-1 {
  padding: 8px !important;
}
.coca-cowi .coca-p-2 {
  padding: 12px !important;
}
.coca-cowi .coca-pt-1 {
  padding-top: 8px !important;
}
.coca-cowi .coca-pt-2 {
  padding-top: 12px !important;
}
.coca-cowi .coca-pb-1 {
  padding-bottom: 8px !important;
}
.coca-cowi .coca-pb-2 {
  padding-bottom: 12px !important;
}
.coca-cowi .coca-pr-1 {
  padding-right: 8px !important;
}
.coca-cowi .coca-pr-2 {
  padding-right: 12px !important;
}
.coca-cowi .coca-pl-1 {
  padding-left: 8px !important;
}
.coca-cowi .coca-pl-2 {
  padding-left: 12px !important;
}
.coca-cowi .coca-py-1 {
  padding: 8px 0px !important;
}
.coca-cowi .coca-py-2 {
  padding: 12px 0px !important;
}
.coca-cowi .coca-px-1 {
  padding: 0px 8px !important;
}
.coca-cowi .coca-px-2 {
  margin: 0px 12px !important;
}
.coca-cowi .coca-m-1 {
  margin: 8px !important;
}
.coca-cowi .coca-m-2 {
  margin: 12px !important;
}
.coca-cowi .coca-mt-1 {
  margin-top: 8px !important;
}
.coca-cowi .coca-mt-2 {
  margin-top: 12px !important;
}
.coca-cowi .coca-mb-1 {
  margin-bottom: 8px !important;
}
.coca-cowi .coca-mb-2 {
  margin-bottom: 12px !important;
}
.coca-cowi .coca-mr-1 {
  margin-right: 8px !important;
}
.coca-cowi .coca-mr-2 {
  margin-right: 12px !important;
}
.coca-cowi .coca-ml-1 {
  margin-left: 8px !important;
}
.coca-cowi .coca-ml-2 {
  margin-left: 12px !important;
}
.coca-cowi .coca-my-1 {
  margin: 8px 0px !important;
}
.coca-cowi .coca-my-2 {
  margin: 12px 0px !important;
}
.coca-cowi .coca-mx-1 {
  margin: 0px 8px !important;
}
.coca-cowi .coca-mx-2 {
  margin: 0px 12px !important;
}

.coca-cowi .coca-cowi-card {
  background: #ffffff !important;
  border: 1px solid #e8e8e8 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06) !important;
  border-radius: 6px !important;
}

.coca-cowi .coca-cowi-hover-card {
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}
.coca-cowi .coca-cowi-hover-card:hover {
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.08) !important;
  transition: all 0.2s ease !important;
  transform: scale(1.03) !important;
}

/* ############################# Typography ############################# */
.coca-cowi {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: rgb(66, 66, 66) !important;
}

.coca-cowi p {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin: 0px !important;
  color: rgb(66, 66, 66) !important;
  word-break: break-word !important;
}
.coca-cowi h1 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 33px !important;
  margin: 0px !important;
  color: #232222 !important;
}
.coca-cowi h2 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: bolder !important;
  font-size: 15px !important;
  line-height: 22px !important;
  margin: 0px !important;
  color: #232222 !important;
}
.coca-cowi h3 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  margin: 0px !important;
  color: #232222 !important;
}
.coca-cowi small {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  margin: 0px !important;
  color: #4a4a4a !important;
}

.coca-cowi a {
  text-decoration: none !important;
}

.coca-cowi .coca-text-white {
  color: #fff !important;
}
.coca-cowi .coca-text-green {
  color: #2e924a !important;
}

/* ############################# Basics ############################# */

.coca-cowi {
  position: relative !important;
}

.coca-cowi .coca-cowi-big-button {
  padding: 12px !important;
  margin: 4px 0px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-tiny-button {
  padding: 4px 8px !important;
  border-radius: 18px !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease !important;
}
.coca-cowi .coca-cowi-tiny-button-icon {
  margin-right: 8px !important;
}
.coca-cowi .coca-cowi-tiny-button:hover {
  transition: all 0.2s ease !important;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.coca-cowi .coca-cowi-icon {
  border: 0 !important;
  max-height: 22px !important;
}

.coca-cowi .coca-inner-title {
  margin-top: 12px !important;
}
/* ############################# corner button positioning ############################# */

.coca-cowi .coca-cowi-corner-button {
  flex-direction: column !important;
  pointer-events: none;
  transition: all 1s ease !important;
  margin: 30px !important;
}

.coca-cowi-corner-button-align-right {
  right: 0px !important;
  align-items: flex-end !important;
}
.coca-cowi-corner-button-align-left {
  left: 0px !important;
  align-items: flex-start !important;
  text-align: left !important;
}

@media only screen and (max-width: 780px) {
  .coca-cowi-corner-button-align-left {
    left: 0px !important;
    margin: 20px !important;
  }
  .coca-cowi-corner-button-align-right {
    right: 0px !important;
    margin: 20px !important;
  }
}

/* ############################# corner button  ############################# */

.coca-cowi .coca-cowi-corner-button-item {
  position: relative;
  pointer-events: all;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.479),
    0px 10px 12px 0px rgba(0, 0, 0, 0.14);
  cursor: pointer !important;
  z-index: 2 !important;
  transition: all 1s ease !important;
}

.coca-cowi .coca-cowi-desktop-size-small {
  width: 40px !important;
  height: 40px !important;
}
.coca-cowi .coca-cowi-desktop-size-medium {
  width: 50px !important;
  height: 50px !important;
}
.coca-cowi .coca-cowi-desktop-size-large {
  width: 60px !important;
  height: 60px !important;
}

@media only screen and (max-width: 780px) {
  .coca-cowi .coca-cowi-mobile-size-small {
    width: 35px !important;
    height: 35px !important;
  }
  .coca-cowi .coca-cowi-mobile-size-medium {
    width: 45px !important;
    height: 45px !important;
  }
  .coca-cowi .coca-cowi-mobile-size-large {
    width: 55px !important;
    height: 55px !important;
  }
}

.coca-cowi .coca-cowi-launcher-icon {
  border: 0 !important;
  width: 50% !important;
  margin-left: -1px !important;
  transition: all 1s ease !important;
}

.coca-cowi .coca-cowi-corner-button-shape-circle {
  border-radius: 200px !important;
}
.coca-cowi .coca-cowi-corner-button-shape-square {
  border-radius: 2px !important;
}
.coca-cowi .coca-cowi-corner-button-shape-rounded-square {
  border-radius: 8px !important;
}
.coca-cowi .coca-cowi-corner-button-shape-app-icon {
  border-radius: 16px !important;
}
/* ############################# sidetab  ############################# */

.coca-cowi .coca-cowi-side-tab {
  flex-direction: column !important;
  transition: all 0.3s ease !important;
  padding: 12px 14px 12px 12px !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-side-tab:hover {
  transition: all 1s ease !important;
  padding: 12px 14px 12px 16px !important;
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.24);
}

.coca-cowi .coca-cowi-side-tab-text {
  writing-mode: vertical-lr !important;
  transform: rotate(180deg) !important;
  font-size: 13px !important;
  font-weight: bolder !important;
}
.coca-cowi .coca-cowi-side-tab-icon {
  margin-top: 8px !important;
  width: 20px !important;
}
.coca-cowi-side-tab-align-right {
  right: 0px !important;
  border-radius: 3px 0 0 3px !important;
}
.coca-cowi-side-tab-align-left {
  left: 0px !important;
  border-radius: 0 3px 3px 0px !important;
}

@media only screen and (max-width: 780px) {
  .coca-cowi .coca-cowi-side-tab {
    padding: 8px 6px 8px 8px !important;
  }
  .coca-cowi .coca-cowi-side-tab:hover {
    padding: 8px 6px 8px 12px !important;
  }

  .coca-cowi .coca-cowi-side-tab-text {
    font-size: 12px !important;
  }
  .coca-cowi .coca-cowi-side-tab-icon {
    margin-top: 6px !important;
    width: 16px !important;
  }
}

/* ############################# OPEN STATE  ############################# */

.coca-cowi .coca-cowi-opened {
  flex-direction: column !important;
  height: 740px !important;
  width: 400px !important;
  border-radius: 20px;
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.10);
  background: #fff !important;
  animation: appear 0.5s !important;
  backdrop-filter: blur(6px) !important;
}

@keyframes appear {
  from {
    transform: scale(0.2);
    opacity: 0;
    transform: translate(10px, 10px);
  }
  to {
    transform: scale(1);
    opacity: 1;
    transform: translate(0, 0);
  }
}

.coca-cowi-opened-align-right,
.coca-cowi-opened-align-bottom-right {
  right: 22px !important;
}
.coca-cowi-opened-align-left,
.coca-cowi-opened-align-bottom-left {
  left: 22px !important;
}
@media only screen and (max-height: 780px) {
  .coca-cowi .coca-cowi-opened {
    bottom: 0px !important;
    height: calc(100vh - 20px) !important;
    width: 380px !important;
  }
  .coca-cowi-opened-align-right,
  .coca-cowi-opened-align-bottom-right {
    right: 0px !important;
  }
  .coca-cowi-opened-align-left,
  .coca-cowi-opened-align-bottom-left {
    left: 0px !important;
  }
}
@media only screen and (max-width: 500px) {
  .coca-cowi .coca-cowi-opened {
    bottom: 0px !important;
    width: 100vw !important;
    height: 80vh !important;
  }
  .coca-cowi-opened-align-right,
  .coca-cowi-opened-align-bottom-right {
    right: 0px !important;
  }
  .coca-cowi-opened-align-left,
  .coca-cowi-opened-align-bottom-left {
    left: 0px !important;
  }
}
.coca-cowi .coca-cowi-bg {
  overflow-x: hidden !important;
  position: relative !important;
  border-radius: 20px 20px 0px 0px !important;
  padding: 0px 12px !important;
  /* overflow-y: auto !important; */
  border: 1px solid rgba(0, 0, 0, 0.04) !important;
}
@media only screen and (max-width: 374px), screen and (max-height: 500px) {
  .coca-cowi .coca-cowi-bg {
    padding: 8px !important;
  }
}
.coca-cowi .coca-cowi-main-container {
  position: relative !important;
  flex-direction: column !important;
  width: 100% !important;
}

.coca-cowi .coca-cowi-title-header {
  padding: 12px 18px !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-chat-back-button:hover {
  transition: all 0.2s ease !important;
  transform: translateX(-4px) !important;
}
.coca-cowi .coca-cowi-header-title,
.coca-cowi .coca-cowi-header-icon {
  transition: all 0.2s ease !important;
  padding: 0px 4px !important;
}
.coca-cowi .coca-cowi-header-icon {
  width: 22px !important;
}
.coca-cowi .coca-cowi-main-body-wrapper {
  align-items: flex-end !important;
  height: calc(100% - 55px) !important;
}
@media only screen and (max-height: 780px) {
  .coca-cowi .coca-cowi-main-body-wrapper {
    height: calc(100% - 48px) !important;
  }
}
.coca-cowi .coca-cowi-empty-state {
  margin-top: 65px !important;
}
/* ############################# header   ############################# */

.coca-cowi .coca-cowi-logo-header {
  width: 100% !important;
  flex: 0 0 40px !important;
}

@media only screen and (max-width: 500px), screen and (max-height: 780px) {
  .coca-cowi .coca-cowi-logo-header {
    flex: 0 0 48px !important;
  }
}

.coca-cowi .coca-cowi-opened-close-button {
  margin: 0px 12px !important;
}
.coca-cowi .coca-cowi-close-button {
  margin: 0px 8px !important;
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-close-button:hover {
  transform: rotate(90deg) !important;
  transition: all 0.2s ease !important;
}

.coca-cowi .coca-cowi-store-logo {
  height: 40px !important;
  margin: 0px 28px !important;
}
.coca-cowi .coca-cowi-store-logo img {
  height: 100% !important;
  max-width: 180px !important;
}

/* ############################# SideBar ############################# */

.coca-cowi .coca-cowi-side-bar {
  flex-direction: column !important;
  flex: 0 0 68px !important;
  height: 100% !important;
}

@media only screen and (max-width: 400px), screen and (max-height: 500px) {
  .coca-cowi .coca-cowi-side-bar {
    flex: 0 0 65px !important;
  }
}
.coca-cowi .coca-cowi-navbar {
  flex-direction: column !important;
}

.coca-cowi .coca-cowi-main-content-area {
  position: relative !important;
  background: #ffffff !important;
  box-shadow: -3px 0 20px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  height: 100% !important;
  animation: lol 0.6s ease-out !important;
}

.coca-cowi .coca-just-cart-margin {
  margin: 0 0px 0px 22px !important;
}

@media only screen and (max-width: 500px) {
  .coca-cowi .coca-just-cart-margin {
    margin: 0 4px 4px 4px !important;
  }
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translatex(50px);
  }

  100% {
    opacity: 1;
    transform: translatex(0px);
  }
}

.coca-cowi .coca-cowi-nav-item {
  padding: 32px 0px !important;
  cursor: pointer !important;
  border-left: 0px solid;
  transition: all 0.5s ease !important;
}
.coca-cowi :not(.coca-cowi-nav-item-selected).coca-cowi-nav-item:hover {
  transition: all 0.5s ease !important;
  background: linear-gradient(
    91.7deg,
    rgba(0, 0, 0, 0.06) 1.93%,
    rgba(0, 0, 0, 0) 97.99%
  ) !important;
  transition: all 0.5s ease !important;
}
.coca-cowi .coca-cowi-nav-item-selected {
  border-left: 4px solid;
  background: linear-gradient(
    91.7deg,
    rgba(0, 0, 0, 0.16) 1.93%,
    rgba(0, 0, 0, 0) 97.99%
  ) !important;
  transition: all 0.5s ease !important;
  font-weight: bolder !important;
}

.coca-cowi .coca-cowi-branding {
  writing-mode: vertical-lr !important;
  transform: rotate(180deg) !important;
  font-size: 13px !important;
  padding: 15px 3px 0px 3px !important;
  font-size: 10px !important;
}

.coca-cowi .coca-cowi-disabled-branding-spacer {
  width: 18px !important;
  height: 18px !important;
}
.coca-cowi .coca-cowi-branding:hover {
  opacity: 1 !important;
  transition: all 0.5s ease !important;
  cursor: pointer !important;
}

/* ############################# Assisitant   ############################# */
.coca-cowi .coca-cowi-assitant {
  flex-direction: column !important;
  width: 100% !important;
  overflow-y: auto !important;
}

.coca-cowi .coca-cowi-assistant-contact-btn {
  padding: 4px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-assistant-contact {
  margin-top: 16px !important;
  text-align: right !important;
}

/* ############################# Assisitant- SearchBox   ############################# */
.coca-cowi .coca-cowi-search-box {
  padding: 14px !important;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.09) !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
}

.coca-cowi .coca-search-input {
  border: none !important;
  font-family: "Open Sans", sans-serif !important;
  flex: 1 0 80% !important;
}
.coca-cowi .coca-search-btn {
  flex: 0 0 20% !important;
}

/* ############################# Assisitant- header    ############################# */
.coca-cowi .coca-cowi-assistant-title-header {
  margin: 18px !important;
}
.coca-cowi .coca-cowi-assistant-title-content {
  margin: 14px 0px !important;
}
.coca-cowi .coca-cowi-assistant-title-emoji {
  font-size: 38px !important;
  line-height: 18px !important;
  margin-bottom: 18px !important;
}

.coca-cowi .coca-cowi-contact-options {
  margin-top: 18px !important;
}
.coca-cowi .coca-cowi-contact-icon {
  display: flex !important;
  align-items: center !important;
  width: 30px !important;
  height: 30px !important;
  padding: 6px !important;
  margin-right: 12px !important;
  border-radius: 14px !important;
  transition: all 0.5s ease !important;
  transform: translateY(0px) !important;
}
.coca-cowi .coca-cowi-contact-icon:hover {
  transition: all 0.5s ease !important;
  transform: translateY(-3px) !important;
  filter: hue-rotate(-25deg);
}
.coca-cowi .coca-cowi-contact-label {
  margin-right: 8px !important;
}
.coca-cowi .coca-cowi-wa-icon {
  background: #45b364 !important;
  box-shadow: 0px 10px 8px rgba(84, 255, 81, 0.45) !important;
}
.coca-cowi .coca-cowi-me-icon {
  background: #138eff !important;
  box-shadow: 0px 10px 8px rgba(66, 221, 255, 0.25) !important;
}
.coca-cowi .coca-cowi-mail-icon {
  background: #ec4c4c !important;
  box-shadow: 0px 10px 8px rgba(255, 66, 66, 0.25) !important;
}
.coca-cowi .coca-cowi-sms-icon {
  background: #a128ff !important;
  box-shadow: 0px 10px 8px rgba(100, 27, 255, 0.25) !important;
}

@media only screen and (max-width: 500px), screen and (max-height: 780px) {
  .coca-cowi .coca-cowi-assistant-title-header h1 {
    font-size: 22px !important;
    line-height: 32px !important;
  }

  .coca-cowi .coca-cowi-assistant-title-content {
    margin: 8px 0px !important;
  }
  .coca-cowi .coca-cowi-contact-options {
    margin-top: 12px !important;
  }

  .coca-cowi .coca-cowi-assistant-title-emoji {
    font-size: 28px !important;
    line-height: 28px !important;
    margin-bottom: 0px !important;
  }
  .coca-cowi .coca-cowi-search-box {
    padding: 8px !important;
    margin-top: 12px !important;
  }
}

@media only screen and (max-width: 500px) {
  .coca-cowi .coca-cowi-assistant-title-emoji {
    display: none !important;
  }
}
@media only screen and (max-height: 520px) {
  .coca-cowi .coca-cowi-assistant-title-content {
    display: none !important;
  }
}

/* ############################# Assisitant- FAQ btns   ############################# */
.coca-cowi .coca-cowi-faq-category-wrapper {
  margin-top: 14px !important;
}
.coca-cowi .coca-cowi-faq-category-btn-wrapper {
  margin-top: 8px !important;
  flex-wrap: wrap !important;
}
.coca-cowi .coca-cowi-faq-category-btn {
  flex: 0 0 49% !important;
}
.coca-cowi .coca-cowi-faq-category-btn-img {
  width: 30px !important;
}
.coca-cowi .coca-cowi-faq-category-btn-img img {
  width: 100% !important;
}
.coca-cowi .coca-cowi-faq-category-btn-card {
  flex: 0 0 47%;
  padding: 8px !important;
  margin: 4px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
/* ############################# Assisitant - faq cards  ############################# */

.coca-cowi .coca-cowi-faq-card {
  margin-top: 8px !important;
  padding: 16px 12px !important;
}

.coca-cowi .coca-cowi-faq-card-image {
  width: 34px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-faq-card-details {
  padding: 0px 12px !important;
  cursor: pointer !important;
  transition: all 0.5s ease !important;
}
.coca-cowi .coca-cowi-faq-card-title {
  font-weight: 700 !important;
  margin-bottom: 4px !important;
}

@media only screen and (max-width: 780px) {
  .coca-cowi .coca-cowi-faq-card {
    margin-top: 8px !important;
    padding: 12px 8px !important;
  }
  .coca-cowi .coca-cowi-faq-card-image {
    width: 28px !important;
  }
}

/* ############################# Assisitant - threads  ############################# */

.coca-cowi .coca-cowi-threads-container {
  position: relative !important;
  border-radius: 20px !important;
}

.coca-cowi .coca-cowi-threads h2 {
  padding: 16px 0px !important;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2) !important;
}

.coca-cowi .coca-cowi-quickread {
  margin: 12px 0px 12px 0px;
}
.coca-cowi .coca-cowi-quickread h3 {
  padding: 12px 16px !important;
}

.coca-cowi .coca-cowi-quickread-content {
  flex: 0 0 85% !important;
}

.coca-cowi .coca-cowi-quickread-item {
  padding: 16px !important;
  border-top: 1px solid rgb(0, 0, 0, 0.2) !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-quickread-item:hover {
  background: rgb(0, 158, 255, 0.06) !important;
}
.coca-cowi .coca-cowi-quickread-img {
  flex: 0 0 15% !important;
  padding-right: 12px !important;
}
.coca-cowi .coca-cowi-quickread-img img {
  width: 100% !important;
}
.coca-cowi .coca-cowi-quickread-description {
  max-height: 34px !important;
  margin-top: 4px !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word !important;
  overflow: hidden !important;
}

/* ############################# Assisitant-chatWindow   ############################# */

.coca-cowi .coca-cowi-chat-message {
  background: #ffffff !important;
  margin-top: 18px !important;
  box-sizing: border-box !important;
  padding: 12px !important;
  max-width: 95% !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02), 0px 4px 5px rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
}

.coca-cowi .coca-cowi-outgo-chat-message {
  flex-direction: row-reverse !important;
}
.coca-cowi .coca-cowi-income-chat-message .coca-cowi-chat-message {
  flex-direction: row-reverse !important;
  border: 1px solid #d7d7d7 !important;
}

/* ############################# Cart   ############################# */

.coca-cowi .coca-cowi-checkout-container {
  padding: 12px 18px !important;
}

.coca-cowi .coca-cowi-checkout-button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
  border-radius: 8px !important;
  padding: 12px 18px !important;
  color: white !important;
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-checkout-button svg {
  margin-right: 8px !important;
}

.coca-cowi-edit-cart-button {
  margin: 8px !important;
}

.coca-cowi-cart-offer-button {
  margin-bottom: 12px !important;
}

.coca-cowi .coca-cowi-cart-item {
  margin-top: 12px !important;
  padding: 12px 8px !important;
}
.coca-cowi .coca-cowi-message-card .coca-cowi-cart-item {
  margin-top: 0px !important;
}
.coca-cowi .coca-cowi-message-card .coca-cowi-product-details p {
  display: none !important;
}
.coca-cowi .coca-cowi-product-image {
  max-width: 40px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}
.coca-cowi .coca-cowi-message-card .coca-cowi-product-image {
  max-width: 32px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}
.coca-cowi .coca-cowi-product-image img {
  width: 100% !important;
}
.coca-cowi .coca-cowi-product-details,
.coca-cowi .coca-cowi-product-actions {
  padding: 0px 12px !important;
  cursor: pointer !important;
  transition: all 0.5s ease !important;
}

.coca-cowi .coca-cowi-product-actions:hover {
  transform: scale(1.3) !important;
  transition: all 0.5s ease !important;
}

/* ############################# Offers   ############################# */

/* ############################# Offers- featured story   ############################# */
.coca-cowi .coca-cowi-story-container h2 {
  padding: 28px 12px 0px 12px !important;
}
@media only screen and (max-width: 374px), screen and (max-height: 500px) {
  .coca-cowi .coca-cowi-story-container h2 {
    padding: 12px 8px 0px 8px !important;
  }
}
.coca-cowi .coca-cowi-story-scroll-container {
  overflow-y: auto !important;
  display: flex !important;
  padding: 22px 4px !important;
}
@media only screen and (max-width: 374px), screen and (max-height: 500px) {
  .coca-cowi .coca-cowi-story-scroll-container {
    padding: 12px 4px !important;
  }
}

.coca-cowi .coca-cowi-featured-story-card {
  position: relative !important;
  width: 90px !important;
  height: 135px !important;
  flex-shrink: 0 !important;
  margin-right: 12px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}
@media only screen and (max-width: 374px), screen and (max-height: 500px) {
  .coca-cowi .coca-cowi-featured-story-card {
    width: 67px !important;
    height: 100px !important;
  }
}

.coca-cowi .coca-cowi-featured-story-card:hover {
  transition: all 0.2s ease !important;
  transform: translateY(-2px);
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.35) !important;
}

.coca-cowi .coca-cowi-featured-offer-text {
  color: white !important;
  margin-top: auto !important;
  padding: 12px 4px;
  font-size: 10px !important;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  z-index: 1 !important;
}

.coca-cowi .coca-cowi-featured-card-gradient-overlay {
  position: absolute !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(
    179.46deg,
    rgba(0, 0, 0, 0) 59.44%,
    rgba(0, 0, 0, 0.59) 99.69%
  ) !important;
}

/* ############################# Offers- Offer Item   ############################# */

.coca-cowi .coca-cowi-offer-thumbnail {
  width: 50px !important;
  height: 77px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-shadow: 0px 8px 9px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  border: 1px solid #e2e2e2 !important;
}

.coca-cowi .coca-cowi-offer-thumbnail:hover {
  transition: all 0.2s ease !important;
  transform: translateY(-2px);
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.35) !important;
}

.coca-cowi .coca-cowi-offer-item {
  margin-top: 12px !important;
  padding: 12px !important;
}
.coca-cowi .coca-cowi-offer-details {
  padding-left: 12px !important;
  margin-left: 14px !important;
  border-left: 1px dashed #d4d2d2 !important;
}

.coca-cowi-coupon-container {
  background: #eefff2 !important;
  border: 1px solid #dbffe5 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  margin: 4px 0px !important;
  padding: 4px 8px !important;
}
.coca-cowi .coca-cowi-coupon-copy {
  padding: 8px !important;
  transition: all 0.2s ease !important;
}
.coca-cowi .coca-cowi-coupon-copy:hover {
  transition: all 0.2s ease !important;
  transform: translateY(-2px);
}

.coca-cowi .coca-cowi-offer-actions {
  justify-content: flex-end !important;
  margin-top: 4px !important;
}

.coca-cowi .coca-cowi-offer-action-button {
  padding: 4px 8px !important;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.coca-cowi .coca-cowi-offer-card {
  margin: 18px !important;
  padding: 18px !important;
  min-width: 200px !important;
}

.coca-cowi .coca-cowi-offer-code {
  margin: 4px 0px 12px 0px !important;
  font-size: 24px !important;
}
.coca-cowi .coca-offer-item-img {
  max-width: 50px !important;
  margin-bottom: 18px !important;
}

.coca-cowi .coca-cowi-offer-specifics {
  margin-top: 12px !important;
}

.coca-cowi .coca-cowi-offer-expanded {
  max-width: 260px !important;
  margin: auto !important;
}

.coca-cowi .coca-cowi-offer-buttons {
  margin-top: 32px !important;
}

.coca-cowi .coca-cowi-offer-email-collector {
  height: 100% !important;
  padding: 12px;
}
.coca-cowi .coca-cowi-offer-email-collector p {
  margin-bottom: 24px !important;
}
.coca-cowi .coca-locked-item-img {
  margin-top: -100px !important;
  margin-bottom: 12px !important;
  max-width: 100% !important;
  filter: drop-shadow(0px 8px 8px rgba(84, 152, 129, 0.5)) !important;
}
.coca-cowi .coca-locked-item-content {
  margin-bottom: 24px !important;
}

.coca-cowi .coca-cowi-lock-popup-wrapper {
  pointer-events: none !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(65, 94, 99, 0.5) 100%
  ) !important;
  align-items: flex-end !important;
}

.coca-cowi .coca-cowi-blur {
  filter: blur(5px) !important;
}

.coca-cowi .coca-cowi-lock-popup {
  pointer-events: all !important;
  padding: 12px !important;
  margin: 16px !important;
  animation: slide-in 1s !important;
}
/* ############################# Offer- story view   ############################# */

.coca-cowi .coca-cowi-story-view {
  flex-direction: column-reverse !important;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 20px;
  flex-direction: column;
  overflow: hidden;
}
.coca-cowi .coca-cowi-story-close-button {
  position: absolute !important;
  top: 21px !important;
  right: 8px !important;
  z-index: 3 !important;
  padding: 7px !important;
  border-radius: 21px !important;
  background: rgba(0, 0, 0, 0.44) !important;
}
.coca-cowi .coca-story-wrapper {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
}
.coca-cowi .coca-story-wrapper img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
/*This following couple of classes are written to override the react-insta-story librarie's default width and height*/

.coca-cowi .coca-cowi-story-view > div:first-child,
.coca-cowi .coca-cowi-story-view > div:first-child > div:nth-child(2),
.coca-cowi .coca-cowi-story-view > div:first-child > div:nth-child(2) > div {
  width: 100% !important;
  height: 100% !important;
}

.coca-cowi .coca-cowi-story-view > div:first-child {
  z-index: 2 !important;
}

.coca-cowi .coca-cowi-story-know-more-button {
  position: absolute !important;
  z-index: 3 !important;
  bottom: 18px !important;
  width: 100%;
}
.coca-cowi .coca-cowi-story-know-more-button span {
  padding: 10px 18px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-size: 14px !important;
  background: rgba(0, 0, 0, 0.6) !important;
  transition: all 0.5s ease !important;
}
.coca-cowi .coca-cowi-story-know-more-button span:hover {
  background: rgba(0, 0, 0, 1) !important;
  transition: all 0.5s ease !important;
  cursor: pointer !important;
}

.coca-cowi .coca-end-story-state {
  position: absolute !important;
  z-index: 3 !important;
  bottom: 240px !important;
  width: 100%;
}
.coca-cowi .coca-cowi-end-state-close {
  justify-content: flex-end !important;
}

.coca-cowi .coca-cowi-story-end-card {
  padding: 20px !important;
  animation: slide-in 1s !important;
  max-width: 70% !important;
}
.coca-cowi .coca-cowi-story-end-card h3 {
  cursor: pointer !important;
}
.coca-cowi .coca-cowi-story-end-card .coca-cowi-big-button {
  margin-top: 20px !important;
  margin-bottom: 16px !important;
}

.coca-cowi .coca-cowi-story-loader {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  background: white !important;
  border-radius: 50% !important;
  animation: load 1.25s ease-in-out infinite !important;
}

@keyframes load {
  0%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.coca-cowi .coca-cowi-email-form input,
.coca-cowi .coca-cowi-email-form button {
  width: 100% !important;
  padding: 12px 16px !important;
  margin-bottom: 8px !important;
  box-sizing: border-box !important;
  border: 0 !important;
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.coca-cowi .coca-cowi-email-form input {
  border: 1px solid #d4d4d4 !important;
  border-radius: 4px !important;
}
.coca-cowi .coca-cowi-email-form button:focus,
.coca-cowi .coca-cowi-email-form input:focus {
  outline-style: inherit !important;
}
.coca-cowi .coca-cowi-email-form button {
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  cursor: pointer !important;
}
/* ############################# faq- story view   ############################# */
.coca-cowi .coca-widget-faq-view {
  font-family: Arial, Helvetica, sans-serif !important;
}
.coca-cowi .coca-widget-faq-view p {
  font-size: 14px !important;
  line-height: 1.8rem !important;
}

.coca-cowi .coca-widget-faq-view li {
  font-size: 14px !important;
  line-height: 1.8rem !important;
}
.coca-cowi .coca-widget-faq-view h1 {
  font-weight: bolder !important;
  font-size: 22px !important;
  line-height: 1.5rem !important;
  margin: 16px 0px !important;
}
.coca-cowi .coca-widget-faq-view h2 {
  font-weight: bolder !important;
  font-size: 22px !important;
  line-height: 1.5rem !important;
  margin: 12px 0px !important;
}
.coca-cowi .coca-widget-faq-view h3 {
  font-weight: bolder !important;
  font-size: 28px !important;
  line-height: 1.5rem !important;
  margin: 8px 0px !important;
}
.coca-cowi .coca-widget-faq-view pre {
  font-size: 14px !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  border: 1px solid #e5e5ec !important;
  padding: 4px !important;
  border-radius: 8px !important;
  background: #eeeef1 !important;
  display: inline !important;
}
