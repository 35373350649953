.custom-toolbar {
  border: none;
  border-bottom: 1px solid #c3ced6;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.rdw-option-wrapper {
  border: none;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #edf2f7;
  transition: all 0.5s ease;
}
.rdw-option-wrapper.rdw-colorpicker-option {
  border: none;
  padding: 0px;
  border-radius: 8px;
  background-color: inherit;
  transition: all 0.5s ease;
}
.rdw-colorpicker-modal {
  right: 5px !important;
  width: 265px;
  height: 205px;
}

.rdw-colorpicker-cube {
  width: 22px;
  border-radius: 50%;
}
.rdw-colorpicker-option {
  box-shadow: none !important;
}

.rdw-option-wrapper:hover,
.rdw-option-active {
  box-shadow: 0px 0px 20px #c9cfe6 inset;
  transition: all 0.5s ease;
}
.block-type {
  border: none;
  min-width: 120px;
  color: #4a5568;
}

.rdw-image-modal,
.rdw-link-modal,
.rdw-embedded-modal {
  border-radius: 4px;
  box-shadow: 0px 20px 20px -5px #f3f3f3;
  height: inherit;
}
.rdw-image-modal-header,
.rdw-embedded-modal-header {
  margin: 0;
}
.rdw-image-modal-header-option,
.rdw-embedded-modal-header-option {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.rdw-image-modal-url-input,
.rdw-embedded-modal-url-input {
  margin: 0;
}
.rdw-image-modal-header-label,
.rdw-embedded-modal-header-label {
  display: none;
}

.rdw-image-modal-btn,
.rdw-link-modal-btn,
.rdw-embedded-modal-btn {
  width: initial;
  height: initial;
  padding: 0.2rem 1.2rem;
  border-radius: 4px;
  background: #2d3748;
  color: white;
}
.rdw-image-modal-btn:disabled,
.rdw-link-modal-btn:disabled,
.rdw-embedded-modal-btn:disabled {
  background: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.rdw-link-modal-input,
.rdw-image-modal-input,
.rdw-image-modal-size-input,
.rdw-embedded-modal-input,
.rdw-embedded-modal-size-input {
  padding: 0.5rem;
  margin: 0;
  min-height: 2.5rem;
}

.CodeMirror {
  height: 100% !important;
}
